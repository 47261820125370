var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ToConfigure" },
    [
      _c(
        "el-main",
        { staticStyle: { "padding-top": "0" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" },
            },
            [
              _vm._l(_vm.cols, function (item, index) {
                return [
                  item.prop != "structer_name"
                    ? _c("el-table-column", {
                        key: index,
                        attrs: {
                          label: item.label,
                          prop: item.prop,
                          align: "center",
                        },
                      })
                    : _c("el-table-column", {
                        key: index,
                        attrs: {
                          label: item.label,
                          prop: item.prop,
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return _vm._l(
                                  row.structer_name,
                                  function (it, ind) {
                                    return _c(
                                      "div",
                                      { key: "bumen" + index + ind },
                                      [_vm._v(_vm._s(it))]
                                    )
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                ]
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "btnse",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.EditGrading(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "btnse",
                            staticStyle: { color: "red" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.deletes(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }