//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    Visibles: {
      type: Boolean,
      default: false
    },
    popup: {
      type: Object,
      default: false
    }
  },
  data: function data() {
    return {
      form: {
        department: '',
        rulese: ''
      },
      rules: {
        department: [{
          required: true,
          message: '请选择部门',
          trigger: 'change'
        }],
        rulese: [{
          required: true,
          message: '请选择规则',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    dialogFormVisible: {
      get: function get() {
        return this.Visibles;
      },
      set: function set(newV) {
        this.$emit('update:visibles', newV);
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    cancel: function cancel() {
      this.dialogTableVisible = false;
    }
  }
};