//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ToConfigure',
  props: {
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      cols: [{
        label: '自定义组名称',
        prop: 'group_name'
      }, {
        label: '部门名称',
        prop: 'structer_name'
      }, {
        label: '创建人',
        prop: 'create_name'
      }, {
        label: '创建时间',
        prop: 'create_time'
      }]
    };
  },
  computed: {},
  watch: {
    tableData: {
      handler: 'getList'
    }
  },
  methods: {
    getList: function getList() {},
    EditGrading: function EditGrading(row) {
      this.$emit('EditGrading', row.id);
    },
    deletes: function deletes(row) {
      this.$emit('deletes', row.id);
    }
  }
};