//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getRankUserList, getPromotionStructure, structureData, addRankUser, getRankUserDetail, delRankUser } from '@/api/updateUserInfo/notice';
import Head from '@/components/head/index';
import StaffSelect from '@/components/StaffSelect/StaffSelectone';
import TimeScreeningse from '@/components/timeScreeningse';
import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
import Popup from '@/components/ToConfigure/popup';
import ToConfigure from '@/components/ToConfigure';
export default {
  name: 'outbound',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '定级人员配置',
      mailListVisibleEs: false,
      cascaderKey: 1,
      popup: {
        Titles: '新增定级人员',
        department: [],
        rulese: []
      },
      ids: '',
      options: [],
      tableData: [],
      structer_id: '',
      ruleForm: {
        department: '',
        rulese: []
      },
      rules: {
        department: [{
          required: true,
          message: '请选择部门',
          trigger: 'change'
        }],
        rulese: [{
          required: true,
          message: '请选择规则',
          trigger: 'change'
        }]
      },
      ruleseArr: []
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.queryClick();
    } else {}
  },
  watch: {
    mailListVisibleEs: function mailListVisibleEs(val) {
      var _this = this;
      if (val == false) {
        this.$nextTick(function () {
          _this.$refs.ruleForm.resetFields();
        });
      }
    }
  },
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse,
    nxDataTabs: nxDataTabs,
    StaffSelect: StaffSelect,
    ToConfigure: ToConfigure,
    Popup: Popup
  },
  methods: {
    queryClick: function queryClick() {
      var _this2 = this;
      //列表数据
      var id = {
        structure_id: this.structer_id
      };
      var data = this.deleteEmptyProperty(id);
      getRankUserList(data).then(function (res) {
        _this2.tableData = res.data;
      });
    },
    getPromo: function getPromo() {
      var _this3 = this;
      getPromotionStructure().then(function (res) {
        _this3.options = res.data;
      });
    },
    getPromoChange: function getPromoChange(val) {
      this.queryClick();
    },
    ModalBox: function ModalBox() {
      var _this4 = this;
      //新增、读取详情
      this.$nextTick(function () {
        _this4.ruleForm.department = '';
        _this4.ids = '';
        _this4.ruleseArr = [];
        ++_this4.cascaderKey;
        if (_this4.$refs.ruleForm != undefined) {
          _this4.$refs.ruleForm.clearValidate();
        }
      });
      this.mailListVisibleEs = true;
      this.popup.rulese = [];
      structureData().then(function (res) {
        _this4.popup.rulese = res.data;
      });
    },
    RuleseChange: function RuleseChange(val) {
      this.ruleseArr = [];
      for (var i in val) {
        var Json = {};

        // 如果当前数组的长度为0
        if (val[i].length == 3) {
          Json.structer_id = val[i][0];
          Json.group_id = val[i][1];
          Json.user_id = val[i][2];
        } else {
          Json.structer_id = val[i][0];
          // Json.group_id = val[i][1];
          Json.user_id = val[i][1];
        }
        this.ruleseArr.push(Json);
      }
    },
    GradingConfiguration: function GradingConfiguration(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var Json = {};
          Json.group_name = _this5.ruleForm.department;
          Json.content = _this5.ruleseArr;
          if (_this5.ids) {
            Json.id = _this5.ids;
          }
          var data = _this5.deleteEmptyProperty(Json);
          // console.log('datadatadata', JSON.stringify(data));
          addRankUser(data).then(function (res) {
            if (_this5.ids) {
              _this5.$notify({
                title: '成功',
                message: '编辑成功',
                type: 'success',
                duration: 2000
              });
            } else {
              _this5.$notify({
                title: '成功',
                message: '新增成功',
                type: 'success',
                duration: 2000
              });
            }
            _this5.queryClick();
            _this5.Reset();
            _this5.mailListVisibleEs = false;
          });
        }
      });
    },
    EditGrading: function EditGrading(id) {
      var _this6 = this;
      this.ids = id;
      ++this.cascaderKey;
      structureData({
        id: this.ids
      }).then(function (item) {
        var data = item.data;
        getRankUserDetail({
          id: id
        }).then(function (res) {
          _this6.popup.rulese = item.data;
          _this6.ruleForm.department = res.data.group_name;
          _this6.ruleForm.rulese = res.data.user_ids.map(function (e) {
            var _this6$getDepartmentN = _this6.getDepartmentNameById(e, _this6.popup.rulese),
              id = _this6$getDepartmentN.id;
            return id;
          });
          _this6.RuleseChange(_this6.ruleForm.rulese);
          _this6.mailListVisibleEs = true;
        });
      });
    },
    Reset: function Reset() {
      this.ids = '';
      this.ruleForm.department = '';
      this.ruleseArr = [];
      this.ruleForm.rulese = [];
    },
    sad: function sad() {
      this.mailListVisibleEs = false;
      this.ruleForm.rulese = [];
    },
    cancel: function cancel() {
      this.mailListVisibleEs = false;
      this.Reset();
    },
    deletes: function deletes(id) {
      var _this7 = this;
      delRankUser({
        id: id
      }).then(function (res) {
        _this7.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success',
          duration: 2000
        });
        _this7.queryClick();
      });
    },
    selectChanged: function selectChanged() {}
  }
};